import { I as INTAKE_STATUS_COLORS, a as INTAKE_STATUS_TEXTS, c as INTAKE_STATUS_ENTERED, d as INTAKE_STATUS_SAMPLE_RECORDED, e as INTAKE_STATUS_AVAILABLE_FOR_UPLOAD } from "./intake.status.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", {
    attrs: {
      "title": "Intake List",
      "has-active": false,
      "search-by": "IntakeCode, CropName",
      "ph-search-by": "Search by Intake, Crop"
    },
    scopedSlots: _vm._u([{
      key: "customFilter",
      fn: function() {
        return [_c("div", {
          staticClass: "status-filter"
        }, [_c("select-filter", {
          attrs: {
            "source": "value",
            "source-label": "text",
            "data-source": _vm.filterOptions,
            "value": _vm.selectedStatus
          },
          on: {
            "change": _vm.onStatusFilterChange
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "action",
      fn: function(_ref) {
        var edit = _ref.edit, record = _ref.record;
        return [_c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Detail")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": "right-circle",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return edit(record);
            }
          }
        })], 1)];
      }
    }])
  }, [_c("url-field", {
    key: "IntakeCode",
    attrs: {
      "data-index": "intakeCode",
      "title": "Intake Code",
      "sorter": true,
      "base-url": "/watties-grower/intake-samples"
    }
  }), _c("text-field", {
    key: "TruckCode",
    attrs: {
      "data-index": "truckCode",
      "title": "Truck",
      "sorter": true
    }
  }), _c("text-field", {
    key: "GrowerId",
    attrs: {
      "data-index": "growerId",
      "title": "Grower",
      "parse": _vm.parseGrowerCode
    }
  }), _c("text-field", {
    key: "ContractCode",
    attrs: {
      "data-index": "contractCode",
      "title": "Contract",
      "parse": _vm.parseContractCode
    }
  }), _c("text-field", {
    key: "CropName",
    attrs: {
      "data-index": "cropName",
      "title": "Crop Code",
      "sorter": true
    }
  }), _c("text-field", {
    key: "TimeIn",
    attrs: {
      "data-index": "timeIn",
      "title": "Time In",
      "parse": _vm.parseTimeIn
    }
  }), _c("tag-field", {
    key: "Status",
    attrs: {
      "data-index": "status",
      "title": "Status",
      "color": _vm.getStatusColor,
      "parse": _vm.parseStatus,
      "sorter": true
    }
  })], 1);
};
var staticRenderFns$1 = [];
var ListIntakeSamples_vue_vue_type_style_index_0_scoped_true_lang = "";
const FIELD_STATUS = "Status";
const __vue2_script$1 = {
  name: "ListIntakeSamples",
  inject: ["resourceName", "crud"],
  data() {
    const filterOptions = [
      { value: "all", text: "All", color: "black" },
      { value: INTAKE_STATUS_ENTERED },
      { value: INTAKE_STATUS_SAMPLE_RECORDED },
      { value: INTAKE_STATUS_AVAILABLE_FOR_UPLOAD }
    ];
    filterOptions.forEach((option) => {
      if (option.value == "all")
        return;
      option.color = INTAKE_STATUS_COLORS[option.value];
      option.text = INTAKE_STATUS_TEXTS[option.value];
    });
    return {
      filterOptions
    };
  },
  computed: {
    selectedStatus() {
      const statusFilter = this.crud.getFilters().get(FIELD_STATUS);
      return statusFilter || { value: "all" };
    }
  },
  beforeMount() {
    this.filterOptions.filter((key) => key.value !== "all").map((key) => {
      let colorStt = key.value === "A" ? "#15B982" : key.value === "E" ? "#EB2F96" : null;
      Object.assign(key, {
        text: INTAKE_STATUS_TEXTS[key.value],
        color: colorStt ? colorStt : INTAKE_STATUS_COLORS[key.value]
      });
    });
  },
  methods: {
    parseCropCode(value, record) {
      if (!value)
        return "";
      return `${value} ${record.cropName}`;
    },
    parseGrowerCode(value, record) {
      if (!value)
        return "";
      return `${value} - ${record.growerName}`;
    },
    parseContractCode(value, record) {
      if (!value)
        return "";
      return `${record.contractId} - ${record.contractCode}`;
    },
    getStatusColor(value) {
      return INTAKE_STATUS_COLORS[value];
    },
    parseStatus(value) {
      return INTAKE_STATUS_TEXTS[value];
    },
    parseTimeIn(value) {
      if (!value)
        return "";
      return this.$moment(value).format("DD/MM/YYYY hh:mm A");
    },
    fetchList() {
      const { count, pageSize } = this.crud.getPagination();
      this.crud.setPagination({ count, page: 1, pageSize });
      this.crud.fetchList(this.customUrl);
    },
    onStatusFilterChange(status) {
      if (status.value == "all") {
        this.crud.deleteFilter(FIELD_STATUS);
        this.fetchList();
        return;
      }
      this.crud.setFilter(FIELD_STATUS, status);
      this.fetchList();
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "5b60c7d7", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListIntakeSamples = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-intake-samples"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.intake-samples",
      "api-url": _vm.apiUrl,
      "edit-route": "/watties-grower/intake-samples/:id",
      "filter-route": "/watties-grower/intake-samples/advanced-search"
    }
  }, [_c("list-intake-samples")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListIntakeSamples
  },
  data() {
    return {
      apiUrl,
      itemsMenu: [
        {
          key: "intakeprocessing",
          title: "Sample Processing",
          path: ""
        },
        {
          key: "intakelist",
          title: "Intake List",
          path: "/watties-grower/intake-samples"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
